import { all, fork } from 'redux-saga/effects';
// import { watchGetProfile, watchLogout, watchLogin, watchGetPortfolio, watchGetEquitySearchResults, watchGetWatchlist, watchGetEquityData, watchGetEquityTT, watchGetEquitySR } from './access.sagas';
import * as Sagas from './access.sagas';

export default function* rootSaga() {
  yield all([
    fork(Sagas.watchLogin),
    fork(Sagas.watchLogout),
    fork(Sagas.watchGetProfile),
    fork(Sagas.watchGetPortfolio),
    fork(Sagas.watchGetWatchlist),

    fork(Sagas.watchGetEquitySearchResults),
    fork(Sagas.watchGetEquityData),
    fork(Sagas.watchGetEquityPrices),
    fork(Sagas.watchGetEquityTT),
    fork(Sagas.watchGetEquitySR),
    fork(Sagas.watchGetEquityRefreshFundamental),
    fork(Sagas.watchGetEquityIntervalHistoryData),
    fork(Sagas.watchGetEquityDeliveryData),
    fork(Sagas.watchGetEquityPositionBuildupData),

    fork(Sagas.watchGetVolumeMovement),
    fork(Sagas.watchGetVolumeMovementDayAvg),
    fork(Sagas.watchGetWeeklyLow),
    fork(Sagas.watchGetIndicesRRGData),
    fork(Sagas.watchGetOptionChainData),
    fork(Sagas.watchGetFuturesData),
    fork(Sagas.watchResetGetEquityData),
    fork(Sagas.watchResetGetEquityPrices),
    fork(Sagas.watchResetGetEquityTT),
    fork(Sagas.watchResetGetEquitySR),
    fork(Sagas.watchResetGetEquityRefreshFundamental),
    fork(Sagas.watchResetGetEquityIntervalHistoryData),
    fork(Sagas.watchResetGetEquityDeliveryData),
    fork(Sagas.watchResetGetEquityPositionBuildupData),
    fork(Sagas.watchResetGetVolumeMovement),
    fork(Sagas.watchResetGetVolumeMovementDayAvg),
    fork(Sagas.watchResetGetWeeklyLow),
    fork(Sagas.watchResetGetIndicesRRGData),
    fork(Sagas.watchResetGetOptionChainData),
    fork(Sagas.watchGetOptionsChainContractDateData),
    fork(Sagas.watchResetOptionsChainContractDateData),
    fork(Sagas.watchResetGetFuturesData),
    fork(Sagas.watchGetEquityRecommendationsData),
    fork(Sagas.watchGetEquityRecommendationsForReviewData),
    fork(Sagas.watchApproveRecommendation),
    fork(Sagas.watchRejectRecommendation),
    fork(Sagas.watchUserPorfolioAddUpdate),
    fork(Sagas.watchUserPorfolioDelete),
    fork(Sagas.watchSendMessage),
    fork(Sagas.watchIndexEquityDerivativesWeightageData),    
    fork(Sagas.watchGetMarketSummaryData),   
    fork(Sagas.watchGetMultipleEquityPrices),   
    fork(Sagas.watchEquityDerivativesWeightageData),
    fork(Sagas.watchEquityDerivativesWeightageHistoryData),
    fork(Sagas.watchGETBulkBlockDeal),
    fork(Sagas.watchGetEquityEvents),
    fork(Sagas.watchAddWatchlist),
    fork(Sagas.watchDeleteSymbolsFromWatchlist),
    fork(Sagas.watchDeleteWatchlist),
    fork(Sagas.watchUpdateWatchlist),
    fork(Sagas.watchGetFuturesExpiryData),
    fork(Sagas.watchResetGetFuturesExpiryData),
    fork(Sagas.watchGetEquitiesByRegion),
    fork(Sagas.watchGetEvents),
    fork(Sagas.watchGetNewsFeeds),
    fork(Sagas.watchGetPortfolioSwingPosition),
    fork(Sagas.watchGetNifty50SwingPosition)
  ]);
}
