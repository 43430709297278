import Cookies from 'js-cookie';
import _ from "lodash";

import * as ActionTypes from '../constants/actiontypes';
import { getData } from '../commons/ChartUtils';
import { SUPER_ADMIN_USERS } from '../constants/appconstants';

const initialState = {
    user: {
        isAuthenticated: typeof Cookies.get('token') !== 'undefined',
        loggedUserObj: {},
        token: Cookies.getJSON('token'),
        isSuperAdminUser: false,
        portfolios: null
    },
    error: null,
};
export default function access(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCEEDED:
        case ActionTypes.PROFILE_SUCCEEDED: {
            return {
                ...state,
                user: {
                    ...state.user,
                    isAuthenticated: true,
                    isSuperAdminUser: action.user && action.user.email && (SUPER_ADMIN_USERS[action.user.email] && SUPER_ADMIN_USERS[action.user.email] === 1) ? true : false,
                    loggedUserObj: action.user,
                    token: Cookies.getJSON('token')
                },
                error: null,
            };
        }
        case ActionTypes.LOGIN_FAILED: {
            return {
                ...state,
                error: action.error,
            };
        }
        // case ActionTypes.USER_WATCHLIST_FAILED:
        // case ActionTypes.USER_PORTFOLIO_FAILED:
        case ActionTypes.PROFILE_FAILED:
        case ActionTypes.LOGOUT_SUCCEEDED: {
            return {
                ...state,
                user: {
                    isAuthenticated: false,
                    isSuperAdminUser: false,
                    loggedUserObj: null,
                    token: null
                },
                portfolios: null,
                portfoliosMap: null,
                portfoliosList: null,
                portfolioNiftyData: null,
                watchlist: null,
                error: null,
            };
        }
        case ActionTypes.UPDATE_COOKIE: {
            return {
                ...state,
                user: {
                    isAuthenticated: typeof Cookies.get('token') !== 'undefined',
                    token: { _id: Cookies.getJSON('token') },
                },
                error: null,
            };
        }
        case ActionTypes.USER_PORTFOLIO_SUCCEEDED: {

            let response = action.portfolio.portfolios
            let cPortfolio = {}
            let portfolioConsolidatedData = {
                originalOverallInvValue: 0,
                yesterdayOverallInvValue: 0,
                todayOverallInvValue: 0
            }
            let allPortfolioObject = {
                'portfolio': 'All',
                'equities': [],
                'originalOverallInvValue': 0,
                'yesterdayOverallInvValue': 0,
                'todayOverallInvValue': 0
            }
            response.map((portfolio, i) => {
                let portfolioName = portfolio['portfolio']
                portfolio['daysChangePct'] = (portfolio['close'] - portfolio['previousclose']) / portfolio['previousclose'] * 100
                portfolio['daysChangePct'] = Math.round(portfolio['daysChangePct'] * 100) / 100
                portfolio['overallChangePct'] = (portfolio['close'] - portfolio['avg_buy_price']) / portfolio['avg_buy_price'] * 100
                portfolio['overallChangePct'] = Math.round(portfolio['overallChangePct'] * 100) / 100

                let portfolioObj = cPortfolio[portfolioName]
                if (!portfolioObj) {
                    portfolioObj = {
                        'portfolio': portfolioName,
                        'equities': [],
                        'originalOverallInvValue': 0,
                        'yesterdayOverallInvValue': 0,
                        'todayOverallInvValue': 0

                    }
                }
                portfolioObj['originalOverallInvValue'] += portfolio['avg_buy_price'] ? (portfolio['avg_buy_price'] * portfolio['qty']) : 0
                portfolioObj['yesterdayOverallInvValue'] += portfolio['previousclose'] ? (portfolio['previousclose'] * portfolio['qty']) : 0
                portfolioObj['todayOverallInvValue'] += portfolio['close'] ? (portfolio['close'] * portfolio['qty']) : 0
                portfolioObj['todaysPortfolioChange'] = Math.round((portfolioObj['todayOverallInvValue'] - portfolioObj['yesterdayOverallInvValue']) * 10) / 10
                portfolioObj['todaysPortfolioChangePct'] = Math.round((portfolioObj['todayOverallInvValue'] - portfolioObj['yesterdayOverallInvValue']) / portfolioObj['yesterdayOverallInvValue'] * 1000) / 10
                portfolioConsolidatedData.originalOverallInvValue += portfolio['avg_buy_price'] ? (portfolio['avg_buy_price'] * portfolio['qty']) : 0
                portfolioConsolidatedData.yesterdayOverallInvValue += portfolio['previousclose'] ? (portfolio['previousclose'] * portfolio['qty']) : 0
                portfolioConsolidatedData.todayOverallInvValue += portfolio['close'] ? (portfolio['close'] * portfolio['qty']) : 0
                portfolioConsolidatedData.todaysPortfolioChange = Math.round((portfolioConsolidatedData.todayOverallInvValue - portfolioConsolidatedData.yesterdayOverallInvValue) * 10) / 10
                portfolioConsolidatedData.todaysPortfolioChangePct = Math.round((portfolioConsolidatedData.todayOverallInvValue - portfolioConsolidatedData.yesterdayOverallInvValue) / portfolioConsolidatedData.yesterdayOverallInvValue * 1000) / 10
                let searchLabel = _.find(state.equitySearchResults, { symbol: portfolio.instrument })
                let sector = 'Other'
                if (searchLabel && !searchLabel.is_index && searchLabel.industry) {
                    sector = searchLabel.industry;
                }

                portfolio['sector'] = sector
                portfolioObj['equities'].push(portfolio)
                cPortfolio[portfolioName] = portfolioObj
                return null;
            })
            allPortfolioObject = {
                equities: response,
                originalOverallInvValue: portfolioConsolidatedData.originalOverallInvValue,
                yesterdayOverallInvValue: portfolioConsolidatedData.yesterdayOverallInvValue,
                todayOverallInvValue: portfolioConsolidatedData.todayOverallInvValue,
                todaysPortfolioChange: portfolioConsolidatedData.todaysPortfolioChange,
                todaysPortfolioChangePct: portfolioConsolidatedData.todaysPortfolioChangePct,
            }
            cPortfolio['All'] = allPortfolioObject
            let cPortfolioKeys = Object.keys(cPortfolio);
            let portfolios_list = response;
            cPortfolioKeys = _.concat(['All'], cPortfolioKeys.slice(0, cPortfolioKeys.length - 1))
            localStorage.setItem('portfolios', JSON.stringify(cPortfolioKeys))
            localStorage.setItem('portfolios_list', JSON.stringify(portfolios_list))

            return {
                ...state,
                //portfolio: action.portfolio,
                portfolios: cPortfolioKeys,
                portfoliosMap: cPortfolio,
                portfoliosList: portfolios_list,
                portfolioNiftyData: action.portfolio.NIFTY,
                error: null,
            };
        }
        case ActionTypes.USER_PORTFOLIO_DELETE_SUCCEEDED:
        case ActionTypes.USER_PORTFOLIO_ADD_UPDATE_SUCCEEDED: {
            return {
                ...state,
                //portfolio: action.portfolio,
                portfoliosUpdated: true,
                error: null,
            };
        }
        case ActionTypes.USER_PORTFOLIO_ADD_UPDATE_DATA_RESET: {
            return {
                ...state,
                //portfolio: action.portfolio,
                portfoliosUpdated: false,
                error: null,
            };
        }
        case ActionTypes.USER_WATCHLIST_ADD_UPDATE_DATA_RESET: {
            return {
                ...state,
                //portfolio: action.portfolio,
                watchlistUpdated: false,
                error: null,
            };
        }
        case ActionTypes.USER_WATCHLIST_UPDATE_SYMBOLS_SUCCEEDED:
        case ActionTypes.USER_WATCHLIST_DELETE_SYMBOLS_SUCCEEDED:
        case ActionTypes.USER_WATCHLIST_DELETE_SUCCEEDED:
        case ActionTypes.USER_WATCHLIST_ADD_SUCCEEDED: {
            return {
                ...state,
                //portfolio: action.portfolio,
                watchlistUpdated: true,
                error: null,
            };
        }
        case ActionTypes.USER_WATCHLIST_SUCCEEDED: {
            return {
                ...state,
                watchlist: action.watchlist,
                error: null,
            };
        }
        case ActionTypes.EQUITY_SEARCH_RESULTS_SUCCEEDED: {
            // localStorage.setItem('equitySearchResults', JSON.stringify(action.equitySearchResults))
            let _searchLabels = action.equitySearchResults;

            let _derivatives = []
            _.each(_searchLabels, (section) => {
                if (section.derivative) {
                    let newsection = _.cloneDeep(section)
                    newsection['is_derivative'] = true;
                    _derivatives.push(newsection);
                }
            })
            // localStorage.setItem('searchLabels', JSON.stringify(_searchLabels))

            return {
                ...state,
                equitySearchResults: action.equitySearchResults,
                searchLabels: [
                    { title: 'equities', result: _searchLabels },
                    { title: 'derivatives', result: _derivatives },
                    { title: 'news', result: [] },
                ],
                error: null,
            };
        }

        case ActionTypes.EQUITY_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                equity: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_DATA_SUCCEEDED: {
            return {
                ...state,
                equity: action.equity,
                error: null,
            };
        }
        case ActionTypes.MULTIPLE_EQUITY_PRICE_RESULTS_SUCCEEDED:
        case ActionTypes.EQUITY_PRICE_RESULTS_SUCCEEDED: {
            let prices = {}
            action.data.forEach(a => {
                prices[a['symbol']] = a['1d']
            });

            localStorage.setItem('equityPrices', JSON.stringify(prices))
            return {
                ...state,
                equityPrices: action.data,
                error: null,
            };
        }
        case ActionTypes.EQUITY_PRICE_RESULTS_FAILED: {
            return {
                ...state,
                equityPrices: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_PRICE_RESET_SUCCEEDED: {
            localStorage.removeItem('equityPrices')
            return {
                ...state,
                equityPrices: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_TTPE_RESET_SUCCEEDED: {
            return {
                ...state,
                equityTtp: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_TTPE_SUCCEEDED: {
            return {
                ...state,
                equityTtp: action.equityTTPE.data,
                error: null,
            };
        }
        case ActionTypes.EQUITY_SR_RESET_SUCCEEDED: {
            return {
                ...state,
                equitySr: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_SR_SUCCEEDED: {
            return {
                ...state,
                equitySr: action.equitySr,
                error: null,
            };
        }

        case ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_RESET_SUCCEEDED: {
            return {
                ...state,
                equityRefreshFundamental: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_SUCCEEDED: {
            return {
                ...state,
                equityRefreshFundamental: action.equityRefreshFundamental,
                error: null,
            };
        }
        case ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                equityIntervalHistoryData: null,
                equityIntervalHistoryData5m: null,
                equityIntervalHistoryData15m: null,
                equityIntervalHistoryData30m: null,
                equityIntervalHistoryData1h: null,
                equityIntervalHistoryData1d: null,
                equityIntervalHistoryData1wk: null,
                error: null,
            }
        }
        case ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_REQUESTED: {
            return {
                ...state,
                equityIntervalHistoryDataUpdated: false,
            }
        }
        case ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_SUCCEEDED: {

            let __reducer = {
                ...state,
                error: null,
                equityIntervalHistoryDataUpdated: true,
                equityIntervalHistoryDataSymbol: action.symbol
            }
            if(!__reducer["equityIntervalHistoryData"]) {
                __reducer["equityIntervalHistoryData"] = {}
            }
            if(!__reducer["equityIntervalHistoryData"][action.symbol]) {
                __reducer["equityIntervalHistoryData"][action.symbol] = {}
            }
            console.log('action.equityIntervalHistoryData::::::');
            console.log(action.equityIntervalHistoryData);
            __reducer["equityIntervalHistoryData"][action.symbol][action._interval] = getData(action.equityIntervalHistoryData.data, _.get(action,'equityIntervalHistoryData.metadata.columns'));
            __reducer["equityIntervalHistoryData" + action._interval] = getData(action.equityIntervalHistoryData.data, _.get(action,'equityIntervalHistoryData.metadata.columns'))
            return __reducer;
        }
        case ActionTypes.EQUITY_DELIVERY_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                dataDelivery1D: null,
                dataDelivery1H: null,
                error: null,
            }
            // return undefined;
        }
        case ActionTypes.EQUITY_DELIVERY_DATA_SUCCEEDED: {
            let y = {
                ...state,
                error: null,
            }

            if (action.dataDelivery1D) {
                y['dataDelivery1D'] = action.dataDelivery1D.data
            }
            if (action.dataDelivery1H) {
                y['dataDelivery1H'] = action.dataDelivery1H.data
            }
            return y;
        }
        case ActionTypes.EQUITY_POSITION_BUILDUP_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                equityPositionBuildup: null,
                error: null,
            };
        }
        case ActionTypes.EQUITY_POSITION_BUILDUP_DATA_SUCCEEDED: {
            return {
                ...state,
                equityPositionBuildup: action.equityPositionBuildup,
                error: null,
            };
        }
        case ActionTypes.VOLUME_MOVEMENT_RESET_SUCCEEDED: {
            return {
                ...state,
                volumeMovement: null,
                error: null,
            };
        }
        case ActionTypes.VOLUME_MOVEMENT_SUCCEEDED: {
            return {
                ...state,
                volumeMovement: action.volumeMovement.data,
                error: null,
            };
        }
        case ActionTypes.VOLUME_MOVEMENT_DAY_AVG_RESET_SUCCEEDED: {
            return {
                ...state,
                volumeMovementDayAvg: null,
                error: null,
            };
        }
        case ActionTypes.VOLUME_MOVEMENT_DAY_AVG_SUCCEEDED: {
            return {
                ...state,
                volumeMovementDayAvg: action.volumeMovementDayAvg.data,
                error: null,
            };
        }
        case ActionTypes.WEEKLY_LOW_RESET_SUCCEEDED: {
            return {
                ...state,
                weeklyLow: null,
                error: null,
            };
        }
        case ActionTypes.WEEKLY_LOW_SUCCEEDED: {
            return {
                ...state,
                weeklyLow: action.weeklyLow,
                error: null,
            };
        }
        case ActionTypes.RRG_DATA_SUCCEEDED: {
            let wTransposedRRGData = [];
            let dTransposedRRGData = [];
            let mTransposedRRGData = []
            const tRrgData = action.rrgData.data;
            const getDailyWeeklyData = (symbol, wData, transposedData) => {
                _.each(wData, (row) => {
                    const newObj = {
                        ...row,
                        symbol,
                        [symbol]: row.rm,
                        rs: row.rs ? Math.round(row.rs * 100) / 100 : row.rs,
                        rm: row.rm ? Math.round(row.rm * 100) / 100 : row.rm,
                        close: row.close ? Math.round(row.close * 100) / 100 : row.close,
                    }
                    transposedData.push(newObj);
                });
                return transposedData;
            }

            _.each(tRrgData, (tRrgDataRow) => {
                const symbol = tRrgDataRow['symbol']
                const wData = tRrgDataRow['weekly_rs_rm_data']
                const dData = tRrgDataRow['daily_rs_rm_data']
                const mData = tRrgDataRow['monthly_rs_rm_data']

                getDailyWeeklyData(symbol, wData, wTransposedRRGData);
                getDailyWeeklyData(symbol, dData, dTransposedRRGData);
                getDailyWeeklyData(symbol, mData, mTransposedRRGData);
            });

            return {
                ...state,
                rrgData: wTransposedRRGData,
                dailyRrgData: dTransposedRRGData,
                monthlyRrgData: mTransposedRRGData,
                error: null,
            };
        }
        case ActionTypes.OPTION_CHAIN_DATA_SUCCEEDED: {
            const optionChainData = action.optionChainData.data;

            return {
                ...state,
                optionChainData: optionChainData,
                error: null,
            };
        }
        case ActionTypes.EVENTS_DATA_SUCCEEDED: {
            return {
                ...state,
                events: action.data,
                error: null,
            };
        }
        case ActionTypes.EVENTS_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                events: null,
                error: null,
            };
        }
        case ActionTypes.NEWSFEEDS_DATA_SUCCEEDED: {
            return {
                ...state,
                newsFeeds: action.data,
                error: null,
            };
        }
        case ActionTypes.NEWSFEEDS_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                newsFeeds: null,
                error: null,
            };
        }
        case ActionTypes.PROTFOLIO_SWING_POSITION_DATA_SUCCEEDED: {
            let portfolioSwingPositionData = action.data;
            let portfolioNames = _.keys(portfolioSwingPositionData)
            let equityNames = {}
            portfolioSwingPositionData['All'] = [];
            _.each(portfolioNames, (portFolioName) => {
                _.each(portfolioSwingPositionData[portFolioName], (equity) => {
                    if (!equityNames[equity.symbol]) {
                        portfolioSwingPositionData['All'].push(equity);
                        equityNames[equity.symbol] = 1;
                    }
                })
            })
            return {
                ...state,
                portfolioSwingPositionData: portfolioSwingPositionData,
                error: null,
            };
        }
        case ActionTypes.PROTFOLIO_SWING_POSITION_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                portfolioSwingPositionData: null,
                error: null,
            };
        }
        case ActionTypes.NIFTY50_SWING_POSITION_DATA_SUCCEEDED: {
            let nifty50SwingPositionData = _.get(action,'data.data');
            return {
                ...state,
                nifty50SwingPositionData: nifty50SwingPositionData,
                error: null,
            };
        }
        case ActionTypes.NIFTY50_SWING_POSITION_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                nifty50SwingPositionData: null,
                error: null,
            };
        }

        case ActionTypes.EQUITY_EVENTS_DATA_SUCCEEDED: {
            return {
                ...state,
                equityEvents: action.data,
                error: null,
            };
        }
        case ActionTypes.EQUITIES_BY_REGION_DATA_SUCCEEDED: {
            let data = action.data;
            const equitiesByState = _.groupBy(data, 'state')
            const equitiesByCity = _.groupBy(data, 'city')
            return {
                ...state,
                equitiesByState: equitiesByState,
                equitiesByCity: equitiesByCity,
                error: null,
            };
        }
        case ActionTypes.EQUITIES_BY_REGION_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                equitiesByState: null,
                equitiesByCity: null,
                error: null,
            };
        }
        case ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_SUCCEEDED: {
            const optionChainContractData = action.optionChainContractData;

            return {
                ...state,
                optionChainContractData: optionChainContractData,
                error: null,
            };
        }
        case ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_RESET_SUCCEEDED: {
            return {
                ...state,
                optionChainContractData: null,
                error: null,
            };
        }
        case ActionTypes.FUTURES_DATA_SUCCEEDED: {
            const futuresData = action.futuresData.data;

            return {
                ...state,
                futuresData: futuresData,
                error: null,
            };
        }
        case ActionTypes.FUTURES_EXPIRY_DATA_SUCCEEDED: {
            const data = action.futuresExpiryData.data;
            let futuresExpiryData = state.futuresExpiryData || {};
            if (data && data[0]) {
                let symbol = data[0].symbol
                let expiry = data[0].expiry
                if (!futuresExpiryData[symbol]) {
                    futuresExpiryData[symbol] = {}
                }
                futuresExpiryData[symbol][expiry] = data

            }
            return {
                ...state,
                futuresExpiryData: futuresExpiryData,
                futuresExpiryUpdated: true,
                error: null,
            };
        }
        case ActionTypes.FUTURES_EXPIRY_DATA_UPDATE_FLAG: {
            return {
                ...state,
                futuresExpiryUpdated: false,
                error: null,
            };
        }
        case ActionTypes.FUTURES_EXPIRY_DATA_RESET_SUCCEEDED: {
            let futuresExpiryData = state.futuresExpiryData || {};
            if (action.symbol) {
                futuresExpiryData[action.symbol] = null
            } else {
                futuresExpiryData = null
            }
            return {
                ...state,
                futuresExpiryData: futuresExpiryData,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_DATA_SUCCEEDED: {
            const recommendations = action.recommendations && action.recommendations.data;
            const recommendationsMeta = action.recommendations && action.recommendations.metaData;
            let finalRecommendations = state.recommendations || []
            if(action.append) {
                finalRecommendations = _.concat(finalRecommendations, recommendations)
            } else {
                finalRecommendations = recommendations;
            }
            return {
                ...state,
                recommendations: finalRecommendations,
                recommendationsMeta: recommendationsMeta,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_SUCCEEDED: {
            const recommendationsForReview = action.recommendationsForReview && action.recommendationsForReview.data;
            const recommendationsForReviewMeta = action.recommendationsForReview && action.recommendationsForReview.metaData;

            return {
                ...state,
                recommendationsForReview: recommendationsForReview,
                recommendationsForReviewMeta: recommendationsForReviewMeta,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_REQUESTED: {
            return {
                ...state,
                recommendationApproved: false,
                recommendationsFetch: false,
                error: null,
            };

        }

        case ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_SUCCEEDED: {
            return {
                ...state,
                recommendationApproved: true,
                recommendationsFetch: true,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_REQUESTED: {
            return {
                ...state,
                recommendationRejected: false,
                recommendationsFetch: false,
                error: null,
            };
        }

        case ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_SUCCEEDED: {
            return {
                ...state,
                recommendationRejected: true,
                recommendationsFetch: true,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_FAILED: {
            return {
                ...state,
                receommendationApproved: false,
                error: null,
            };
        }
        case ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_FAILED: {
            return {
                ...state,
                receommendationRejected: false,
                error: null,
            };
        }
        case ActionTypes.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_SUCCEEDED: {
            return {
                ...state,
                derivativesByWeightage: action.equities.data,
                error: null,
            };
        }
        case ActionTypes.DERIVATIVE_WEIGHTAGE_HISTORY_DATA_SUCCEEDED: {
            return {
                ...state,
                derivativesWeightageHistory: action.derivatives,
                error: null,
            };
        }

        case ActionTypes.DERIVATIVE_WEIGHTAGE_DATA_SUCCEEDED: {
            let derivatives = action.derivatives;
            let indexOfDerivatives = {};
            _.each(derivatives, (derivative) => {
                let indexes = derivative.indexes && derivative.indexes.split(',')
                let indexesArr = []
                _.each(indexes, (index) => {
                    if (index) {
                        let arr = index.split('|');
                        indexesArr.push({ symbol: arr[0], weightage: arr[1] })
                    }
                })
                indexOfDerivatives[derivative.symbol] = indexesArr
            })
            return {
                ...state,
                indexesByWeightage: indexOfDerivatives,
                error: null,
            };
        }
        case ActionTypes.MARKET_SUMMARY_DATA_SUCCEEDED: {
            return {
                ...state,
                marketSummary: action.marketSummary,
                error: null,
            };
        }
        case ActionTypes.BULK_BLOCK_DEAL_DATA_SUCCEEDED: {
            let bulkBlockDataForEquity = state.bulkBlockDataForEquity || []
            let bulkBlockDataForAll = state.bulkBlockDataForAll || []
            if (action.datatype === 'single') {
                bulkBlockDataForEquity = action.data.data;
            } else {
                bulkBlockDataForAll = action.data.data;
            }
            return {
                ...state,
                marketSummary: action.marketSummary,
                error: null,
                bulkBlockDataForAll: bulkBlockDataForAll,
                bulkBlockDataForEquity: bulkBlockDataForEquity
            };
        }
        case ActionTypes.EQUITY_DATA_FAILED:
        case ActionTypes.EQUITY_TTPE_FAILED:
        case ActionTypes.EQUITY_SR_FAILED:
        case ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_FAILED:
        case ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_FAILED:
        case ActionTypes.EQUITY_DELIVERY_DATA_FAILED:
        case ActionTypes.EQUITY_POSITION_BUILDUP_DATA_FAILED:
        case ActionTypes.VOLUME_MOVEMENT_FAILED:
        case ActionTypes.VOLUME_MOVEMENT_DAY_AVG_FAILED: {
            return {
                ...state,
                error: action.error,
            };
        }

        default:
            return state;
    }
}
