import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';


class SuspenseView extends Component {
  render() {
    return (
      <div className="Home">
        {/* <!-- ======= Hero Section ======= --> */}
        <section className="d-flexr hero-margin-header">
        </section>

        <main id="main">
          {/* <!-- ======= About Us Section ======= --> */}
          <div className="container" data-aos="fade-up">
            <div className="row col-lg-12">
              <div className="loading">
                <Col>
                  <div class="card shadow">
                    <div class="card-1 animate-pulse"> </div>
                    <div class="card-2-graph-placehlder-row p-3">
                      <div class="row">
                        {/* <div class="col-2">
                                            </div> */}
                        <div class="col-8">
                          <div class="inner-card equity-graph-placehlder-row animate-pulse"> </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        {/* <div class="col-3">
                                            </div> */}
                        <div class="col-6">
                          <div class="inner-card equity-graph-placehlder-row animate-pulse"> </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        {/* <div class="col-3">
                                            </div> */}
                        <div class="col-6">
                          <div class="inner-card equity-graph-placehlder-row animate-pulse"> </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>

              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}


export default SuspenseView;
