import { put, call, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import Cookies from 'js-cookie';

import * as ActionTypes from '../constants/actiontypes';
import { get, post, deleteCall } from '../utils/api';
import lGet from 'lodash/get';
import * as EndPoints from '../constants/endpoints';
// import { USER_PROFILE_ENDPOINT, USER_PORTFOLIO_ENDPOINT, USER_WATCHLIST_ENDPOINT, EQUITY_DATA_ENDPOINT, EQUITY_TTPE_ENDPOINT, EQUITY_WEEKLYSR_ENDPOINT, EQUITY_SEARCH_RESULTS_ENDPOINT } from '../constants/endpoints';

let inFn = {
  login: function* (action) {
    const { email, password } = action;
    try {
      yield call(post, '/login', { email, password },);
      // const inOneWeek = new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 7));
      // Cookies.set('auth__flow__spa__loggedUserObj', response.data, { expires: inOneWeek });
      // yield put({ type: ActionTypes.LOGIN_SUCCEEDED, user: response.data });
    } catch (error) {
      if (lGet(error.response, 'data')) {
        yield put({ type: ActionTypes.LOGIN_FAILED, error: error.response.data });
      } else {
        yield put({ type: ActionTypes.LOGIN_FAILED, error });
      }
    }
  },
  logout: function* () {
    try {
      Cookies.remove('token');
      yield put({ type: ActionTypes.LOGOUT_SUCCEEDED });
    } catch (error) {
      if (lGet(error.response, 'data')) {
        yield put({ type: ActionTypes.LOGOUT_FAILED, error: error.response.data });
      } else {
        yield put({ type: ActionTypes.LOGOUT_FAILED, error: error.response });
      }
    }
  },
  getProfile: function* (action) {
    try {
      const response = yield call(get, EndPoints.USER_PROFILE_ENDPOINT,);
      yield put({ type: ActionTypes.PROFILE_SUCCEEDED, user: response });
    } catch (error) {
      yield put({ type: ActionTypes.PROFILE_FAILED, error: error.response });
    }
  },
  getWatchlist: function* () {
    try {
      const response = yield call(get, EndPoints.USER_WATCHLIST_ENDPOINT,);
      yield put({ type: ActionTypes.USER_WATCHLIST_SUCCEEDED, watchlist: response });
    } catch (error) {
      yield put({ type: ActionTypes.USER_WATCHLIST_FAILED, error: error.response });
    }
  },
  addWatchlist: function* (action) {
    const { watchlistData } = action;
    try {
      yield call(post, EndPoints.USER_WATCHLIST_ADD_ENDPOINT, watchlistData);
      let t = { type: ActionTypes.USER_WATCHLIST_ADD_SUCCEEDED }
      t.watchlistUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_WATCHLIST_ADD_FAILED, error: error.response });
    }
  },  
  updateWatchlist: function* (action) {
    const { watchlistData, id } = action;
    try {
      yield call(post, EndPoints.USER_WATCHLIST_ADD_SYMBOLS_ENDPOINT.replace('{watchlistid}', id), watchlistData);
      let t = { type: ActionTypes.USER_WATCHLIST_UPDATE_SYMBOLS_SUCCEEDED }
      t.portfolioUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_WATCHLIST_UPDATE_SYMBOLS_FAILED, error: error.response });
    }
  },  
  deleteSymbolsFromWatchlist: function* (action) {
    const { watchlistData, id } = action;
    try {
      yield call(deleteCall, EndPoints.USER_WATCHLIST_DELETE_SYMBOLS_ENDPOINT.replace('{watchlistid}', id), watchlistData);
      let t = { type: ActionTypes.USER_WATCHLIST_DELETE_SYMBOLS_SUCCEEDED }
      t.portfolioUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_WATCHLIST_UPDATE_SYMBOLS_FAILED, error: error.response });
    }
  },      
  deleteWatchlist: function* (action) {
    const watchlistName = action.watchlistName;
    try {
      yield call(deleteCall, EndPoints.USER_WATCHLIST_DELETE_BY_NAME_ENDPOINT.replace('{watchlistname}', watchlistName));
      let t = { type: ActionTypes.USER_WATCHLIST_DELETE_SUCCEEDED }
      t.portfolioUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_WATCHLIST_DELETE_FAILED, error: error.response });
    }
  },

  getPortfolio: function* () {
    try {
      const response = yield call(get, EndPoints.USER_PORTFOLIO_ENDPOINT,);
      yield put({ type: ActionTypes.USER_PORTFOLIO_SUCCEEDED, portfolio: response });
    } catch (error) {
      yield put({ type: ActionTypes.USER_PORTFOLIO_FAILED, error: error.response });
    }
  },

  getEquitySearchResults: function* () {
    try {
      const response = yield call(get, EndPoints.EQUITY_SEARCH_RESULTS_ENDPOINT,);
      yield put({ type: ActionTypes.EQUITY_SEARCH_RESULTS_SUCCEEDED, equitySearchResults: response.data });
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_SEARCH_RESULTS_FAILED, error: error.response });
    }
  },
  getEquityPrices: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_PRICE_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_PRICES_ENDPOINT.replace('{symbol}', symbol ? ('/' + symbol) : ''));
        yield put({ type: ActionTypes.EQUITY_PRICE_RESULTS_SUCCEEDED, equityPrices: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_PRICE_RESULTS_FAILED, error: error.response });
    }
  },
  getMultipleEquityPrices: function* (action) {
    const { symbols, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.MULTIPLE_EQUITY_PRICE_RESET_SUCCEEDED });
        return;
      } else {
        console.log('%%%in get equity mutliple prices');
        const response = yield call(post, EndPoints.MULTIPLE_EQUITY_PRICES_ENDPOINT, symbols);
        yield put({ type: ActionTypes.MULTIPLE_EQUITY_PRICE_RESULTS_SUCCEEDED, equityPrices: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.MULTIPLE_EQUITY_PRICE_RESULTS_FAILED, error: error.response });
    }
  },  
  
  getEquityData: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_DATA_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_DATA_ENDPOINT.replace('{symbol}', symbol));
        yield put({ type: ActionTypes.EQUITY_DATA_SUCCEEDED, equity: response.data });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_DATA_FAILED, error: error.response });
    }
  },
  getEquityDerivativeWeightage: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_DATA_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.DERIVATIVE_WEIGHTAGE.replace('{symbol}', symbol || ''));
        yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_DATA_SUCCEEDED, derivatives: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_DATA_FAILED, error: error.response });
    }
  },  
  getEquityDerivativeWeightageHistory: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_HISTORY_DATA_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.DERIVATIVE_WEIGHTAGE_HISTORY.replace('{symbol}', symbol || ''));
        yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_HISTORY_DATA_SUCCEEDED, derivatives: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.DERIVATIVE_WEIGHTAGE_HISTORY_DATA_FAILED, error: error.response });
    }
  },    
  getIndexEquityDerivativesWeightageData: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE.replace('{symbol}', symbol));
        yield put({ type: ActionTypes.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_SUCCEEDED, equities: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_FAILED, error: error.response });
    }
  },  
  getEquityTT: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_TTPE_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_TTPE_ENDPOINT.replace('{symbol}', symbol),);
        yield put({ type: ActionTypes.EQUITY_TTPE_SUCCEEDED, equityTTPE: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_TTPE_FAILED, error: error.response });
    }
  },
  getEquitySR: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_SR_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_WEEKLYSR_ENDPOINT.replace('{symbol}', symbol),);
        yield put({ type: ActionTypes.EQUITY_SR_SUCCEEDED, equitySr: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_SR_FAILED, error: error.response });
    }
  },
  getEquityRefreshFundamental: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_REFRESH_FUNDAMENTAL_ENDPOINT.replace('{symbol}', symbol),);
        yield put({ type: ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_SUCCEEDED, equityRefreshFundamental: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_FAILED, error: error.response });
    }
  },
  getEquityIntervalHistoryData: function* (action) {
    const { symbol, _interval, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_INTERVAL_HISTORY_DATA_ENDPOINT.replace('{symbol}', symbol).replace('{interval}', _interval),);
        yield put({ type: ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_SUCCEEDED, equityIntervalHistoryData: response, _interval: _interval, symbol: symbol });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_FAILED, error: error.response });
    }
  },
  // resetEquityDeliveryData: function*(action) {
  //   const { reset } = action;
  //   if (reset) {
  //     yield put({ type: ActionTypes.EQUITY_DELIVERY_DATA_RESET_SUCCEEDED, _reset: null });
  //     return;
  //   }
  // },
  getEquityDeliveryData: function* (action) {
    const { symbol, _interval, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_DELIVERY_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_DELIVERY_DATA_ENDPOINT.replace('{symbol}', symbol).replace('{interval}', _interval),);

        let dataDelivery = null
        switch (_interval) {
          case '1d':
            dataDelivery = 'dataDelivery1D'
            break;
          case '1h':
            dataDelivery = 'dataDelivery1H'
            break;
          default:
            dataDelivery = null;
        }
        let t = { type: ActionTypes.EQUITY_DELIVERY_DATA_SUCCEEDED }
        t[dataDelivery] = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_DELIVERY_DATA_FAILED, error: error.response });
    }
  },
  getEquityPositionBuildupData: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_POSITION_BUILDUP_DATA_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_POSITION_BUILDUP_DATA_ENDPOINT.replace('{symbol}', symbol),);
        yield put({ type: ActionTypes.EQUITY_POSITION_BUILDUP_DATA_SUCCEEDED, equityPositionBuildup: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_POSITION_BUILDUP_DATA_FAILED, error: error.response });
    }
  },

  getVolumeMovement: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.VOLUME_MOVEMENT_RESET_SUCCEEDED });
        return;
      } else {
        let ep;
        if (symbol) {
          ep = EndPoints.VOLUME_MOVEMENT_SYMBOL_ENDPOINT.replace('{symbol}', symbol);
        } else {
          ep = EndPoints.VOLUME_MOVEMENT_ENDPOINT;
        }
        const response = yield call(get, ep,);
        yield put({ type: ActionTypes.VOLUME_MOVEMENT_SUCCEEDED, volumeMovement: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.VOLUME_MOVEMENT_FAILED, error: error.response });
    }
  },
  getVolumeMovementDayAvg: function* (action) {
    const { symbol, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.VOLUME_MOVEMENT_DAY_AVG_RESET_SUCCEEDED });
        return;
      } else {
        let ep;
        if (symbol) {
          ep = EndPoints.VOLUME_MOVEMENT_DAY_AVG_SYMBOL_ENDPOINT.replace('{symbol}', symbol);
        } else {
          ep = EndPoints.VOLUME_MOVEMENT_DAY_AVG_ENDPOINT;
        }
        const response = yield call(get, ep,);
        yield put({ type: ActionTypes.VOLUME_MOVEMENT_DAY_AVG_SUCCEEDED, volumeMovementDayAvg: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.VOLUME_MOVEMENT_DAY_AVG_FAILED, error: error.response });
    }
  },
  getWeeklyLow: function* (action) {
    const { reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.WEEKLY_LOW_RESET_SUCCEEDED });
        return;
      } else {
        const response = yield call(get, EndPoints.WEEKLY_LOW_ENDPOINT,);
        yield put({ type: ActionTypes.WEEKLY_LOW_SUCCEEDED, weeklyLow: response });
      }
    } catch (error) {
      yield put({ type: ActionTypes.WEEKLY_LOW_FAILED, error: error.response });
    }
  },
  getIndicesRRGData: function* (action) {
    const { equities, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.RRG_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(post, EndPoints.SECTOR_INDICES_RRG_DATA_ENDPOINT, equities);
        let t = { type: ActionTypes.RRG_DATA_SUCCEEDED }
        t.rrgData = response.data
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.RRG_DATA_FAILED, error: error.response });
    }
  },
  getOptionsChainData: function* (action) {
    const { equities, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.OPTION_CHAIN_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(post, EndPoints.OPTION_CHAIN_DATA_ENDPOINT, equities);
        let t = { type: ActionTypes.OPTION_CHAIN_DATA_SUCCEEDED }
        t.optionChainData = response.data
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.OPTION_CHAIN_DATA_FAILED, error: error.response });
    }
  },
  getOptionsChainContractDateData: function* (action) {
    const { symbol, expiry, contractDate, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        let ep = EndPoints.OPTION_CHAIN_CONTRACT_DATE_DATA_ENDPOINT.replace('{symbol}', symbol).replace('{expiry}', expiry).replace('{contract_date}', contractDate);
        const response = yield call(get, ep);
        let t = { type: ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_SUCCEEDED }
        t.optionChainContractData = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_FAILED, error: error.response });
    }
  },

  getFuturesData: function* (action) {
    const { equities, reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.FUTURES_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(post, EndPoints.FUTURES_DATA_ENDPOINT, equities);
        let t = { type: ActionTypes.FUTURES_DATA_SUCCEEDED }
        t.futuresData = response.data
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.FUTURES_DATA_FAILED, error: error.response });
    }
  },
  getFuturesExpiryData: function* (action) {
    const { symbol, reset, expiry } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.FUTURES_EXPIRY_DATA_RESET_SUCCEEDED, _reset: null, symbol: symbol, expiry: expiry });
        return;
      } else {
        const response = yield call(get, EndPoints.FUTURES_EXPIRY_DATA_ENDPOINT.replace('{symbol}', symbol).replace('{expiry}', expiry));
        let t = { type: ActionTypes.FUTURES_EXPIRY_DATA_SUCCEEDED }
        t.futuresExpiryData = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.FUTURES_EXPIRY_DATA_FAILED, error: error.response });
    }
  },
  getEquityRecommendationsData: function* (action) {
    const { count, reset, query } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_DATA_SUCCEEDED, _reset: null });
        return;
      } else {
        const count1 = (count && '/' + count) || '';
        let ep = EndPoints.EQUITY_RECOMMENDATIONS.replace('{count}', count1);
        const response = yield call(get, ep, query);
        let t = { type: ActionTypes.EQUITY_RECOMMENDATIONS_DATA_SUCCEEDED }
        t.recommendations = response
        t.append = query && query.append
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_DATA_FAILED, error: error.response });
    }
  },
  getMarketSummaryData: function* (action) {
    const { reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.MARKET_SUMMARY_DATA_SUCCEEDED, _reset: null });
        return;
      } else {
        let ep = EndPoints.MARKET_SUMMARY;
        const response = yield call(get, ep);
        let t = { type: ActionTypes.MARKET_SUMMARY_DATA_SUCCEEDED }
        t.marketSummary = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.MARKET_SUMMARY_DATA_FAILED, error: error.response });
    }
  },

  getEquityRecommendationsForReviewData: function* (action) {
    const { reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_RECOMMENDATIONS_FOR_REVIEW);
        let t = { type: ActionTypes.EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_SUCCEEDED }
        t.recommendationsForReview = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_FAILED, error: error.response });
    }
  },
  approveRecommendation: function* (action) {
    const { recommendationId } = action;
    try {
      const response = yield call(post, EndPoints.EQUITY_RECOMMENDATIONS_REVIEW_APPROVE.replace('{recommendationId}', recommendationId));
      let t = { type: ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_SUCCEEDED }
      t.recommendations = response
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_FAILED, error: error.response });
    }
  },
  rejectRecommendation: function* (action) {
    const { recommendationId } = action;
    try {
      const response = yield call(post, EndPoints.EQUITY_RECOMMENDATIONS_REVIEW_REJECT.replace('{recommendationId}', recommendationId));
      let t = { type: ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_SUCCEEDED }
      t.recommendations = response
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_FAILED, error: error.response });
    }
  },
  updatePortfolio: function* (action) {
    const { portfolioData } = action;
    try {
      yield call(post, EndPoints.USER_ADD_UPDATE_PORTFOLIO_ENDPOINT, portfolioData);
      let t = { type: ActionTypes.USER_PORTFOLIO_ADD_UPDATE_SUCCEEDED }
      t.portfolioUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_PORTFOLIO_ADD_UPDATE_FAILED, error: error.response });
    }
  },  
  deletePortfolio: function* (action) {
    const portfolioName = action.portfolioName;
    try {
      yield call(deleteCall, EndPoints.USER_DELETE_PORTFOLIO_ENDPOINT.replace('{portfolioName}', portfolioName));
      let t = { type: ActionTypes.USER_PORTFOLIO_DELETE_SUCCEEDED }
      t.portfolioUpdate = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.USER_PORTFOLIO_DELETE_FAILED, error: error.response });
    }
  },  
  sendMessage: function* (action) {
    const message = action.message;
    try {
      yield call(post, EndPoints.SEND_MESSAGE, message);
      let t = { type: ActionTypes.MESSAGE_SEND_SUCCEEDED }
      t.messageSent = true
      yield put(t);
    } catch (error) {
      yield put({ type: ActionTypes.MESSAGE_SEND_FAILED, error: error.response });
    }
  },    

  getBulkBlockDeal: function* (action) {
    const { reset, symbol } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.BULK_BLOCK_DEAL_DATA_SUCCEEDED, _reset: null });
        return;
      } else {
        let replacementValue =  symbol ? ('/' + symbol) : ''
        let type = symbol ? 'single' : 'all' 
        const response = yield call(get, EndPoints.BULK_BLOCK_DEAL.replace('{symbol}', replacementValue));
        let t = { type: ActionTypes.BULK_BLOCK_DEAL_DATA_SUCCEEDED }
        t.data = response
        t.datatype = type
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.BULK_BLOCK_DEAL_DATA_FAILED, error: error.response });
    }
  },
  getEquityEvents: function* (action) {
    const { reset, symbol } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITY_EVENTS_DATA_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITY_EVENTS.replace('{symbol}', symbol));
        let t = { type: ActionTypes.EQUITY_EVENTS_DATA_SUCCEEDED }
        t.data = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITY_EVENTS_DATA_FAILED, error: error.response });
    }
  }, 
  getEvents: function* (action) {
    const { reset, query } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EVENTS_DATA_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.EVENTS, query);
        let t = { type: ActionTypes.EVENTS_DATA_SUCCEEDED }
        t.data = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EVENTS_DATA_FAILED, error: error.response });
    }
  },  
  getEquitiesByRegion: function* (action) {
    const { reset } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.EQUITIES_BY_REGION_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.EQUITIES_BY_REGION);
        let t = { type: ActionTypes.EQUITIES_BY_REGION_DATA_SUCCEEDED }
        t.data = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.EQUITIES_BY_REGION_DATA_FAILED, error: error.response });
    }
  }, 
  getNewsFeed: function* (action) {
    const { reset, query } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.NEWSFEEDS_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.NEWSFEEDS, query);
        let t = { type: ActionTypes.NEWSFEEDS_DATA_SUCCEEDED }
        t.data = response.data
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.NEWSFEEDS_DATA_FAILED, error: error.response });
    }
  },  
  getPortfolioSwingPosition: function* (action) {
    const { reset, query } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.PROTFOLIO_SWING_POSITION_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.PROTFOLIO_SWING_POSITION, query);
        let t = { type: ActionTypes.PROTFOLIO_SWING_POSITION_DATA_SUCCEEDED }
        t.data = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.PROTFOLIO_SWING_POSITION_DATA_FAILED, error: error.response });
    }
  },
  getNifty50SwingPosition: function* (action) {
    const { reset, query } = action;
    try {
      if (reset) {
        yield put({ type: ActionTypes.NIFTY50_SWING_POSITION_DATA_RESET_SUCCEEDED, _reset: null });
        return;
      } else {
        const response = yield call(get, EndPoints.NIFTY50_SWING_POSITION, query);
        let t = { type: ActionTypes.NIFTY50_SWING_POSITION_DATA_SUCCEEDED }
        t.data = response
        yield put(t);
      }
    } catch (error) {
      yield put({ type: ActionTypes.NIFTY50_SWING_POSITION_DATA_FAILED, error: error.response });
    }
  },            
}


/**User Sagas*/
export function* watchLogin() {
  yield takeLatest(ActionTypes.LOGIN_REQUESTED, inFn.login);
}

export function* watchLogout() {
  yield takeLatest(ActionTypes.LOGOUT_REQUESTED, inFn.logout);
}

export function* watchGetProfile() {
  yield takeLatest(ActionTypes.PROFILE_REQUESTED, inFn.getProfile);
}

export function* watchGetWatchlist() {
  yield takeLatest(ActionTypes.USER_WATCHLIST_REQUESTED, inFn.getWatchlist);
}

export function* watchGetPortfolio() {
  yield takeLatest(ActionTypes.USER_PORTFOLIO_REQUESTED, inFn.getPortfolio);
}

/**Equity Sagas*/
export function* watchGetEquitySearchResults() {
  yield takeLatest(ActionTypes.EQUITY_SEARCH_RESULTS_REQUESTED, inFn.getEquitySearchResults);
}

export function* watchResetGetEquityData() {
  yield takeLatest(ActionTypes.EQUITY_DATA_RESET, inFn.getEquityData);
}
export function* watchGetEquityData() {
  yield takeLatest(ActionTypes.EQUITY_DATA_REQUESTED, inFn.getEquityData);
}

export function* watchResetGetEquityPrices() {
  yield takeLatest(ActionTypes.EQUITY_PRICE_RESET, inFn.getEquityPrices);
}
export function* watchGetEquityPrices() {
  yield takeLatest(ActionTypes.EQUITY_PRICE_REQUESTED, inFn.getEquityPrices);
}

export function* watchGetMultipleEquityPrices() {
  yield takeLatest(ActionTypes.MULTIPLE_EQUITY_PRICE_REQUESTED, inFn.getMultipleEquityPrices);
}

export function* watchResetGetEquityTT() {
  yield takeLatest(ActionTypes.EQUITY_TTPE_RESET, inFn.getEquityTT);
}
export function* watchGetEquityTT() {
  yield takeLatest(ActionTypes.EQUITY_TTPE_REQUESTED, inFn.getEquityTT);
}

export function* watchResetGetEquitySR() {
  yield takeLatest(ActionTypes.EQUITY_SR_RESET, inFn.getEquitySR);
}
export function* watchGetEquitySR() {
  yield takeLatest(ActionTypes.EQUITY_SR_REQUESTED, inFn.getEquitySR);
}

export function* watchResetGetEquityRefreshFundamental() {
  yield takeLatest(ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_RESET, inFn.getEquityRefreshFundamental);
}
export function* watchGetEquityRefreshFundamental() {
  yield takeLatest(ActionTypes.EQUITY_REFRESH_FUNDAMENTAL_REQUESTED, inFn.getEquityRefreshFundamental);
}

export function* watchResetGetEquityIntervalHistoryData() {
  yield takeLatest(ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_RESET, inFn.getEquityIntervalHistoryData);
}
export function* watchGetEquityIntervalHistoryData() {
  yield takeEvery(ActionTypes.EQUITY_INTERVAL_HISTORY_DATA_REQUESTED, inFn.getEquityIntervalHistoryData);
}

export function* watchResetGetEquityDeliveryData() {
  yield takeLeading(ActionTypes.EQUITY_DELIVERY_DATA_RESET, inFn.getEquityDeliveryData);
}
export function* watchGetEquityDeliveryData() {
  yield takeEvery(ActionTypes.EQUITY_DELIVERY_DATA_REQUESTED, inFn.getEquityDeliveryData);
}

export function* watchResetGetEquityPositionBuildupData() {
  yield takeLatest(ActionTypes.EQUITY_POSITION_BUILDUP_DATA_RESET, inFn.getEquityPositionBuildupData);
}
export function* watchGetEquityPositionBuildupData() {
  yield takeLatest(ActionTypes.EQUITY_POSITION_BUILDUP_DATA_REQUESTED, inFn.getEquityPositionBuildupData);
}


/**Generic Sagas*/
export function* watchResetGetVolumeMovement() {
  yield takeLatest(ActionTypes.VOLUME_MOVEMENT_RESET, inFn.getVolumeMovement);
}
export function* watchGetVolumeMovement() {
  yield takeLatest(ActionTypes.VOLUME_MOVEMENT_REQUESTED, inFn.getVolumeMovement);
}

export function* watchResetGetVolumeMovementDayAvg() {
  yield takeLatest(ActionTypes.VOLUME_MOVEMENT_DAY_AVG_RESET, inFn.getVolumeMovementDayAvg);
}
export function* watchGetVolumeMovementDayAvg() {
  yield takeLatest(ActionTypes.VOLUME_MOVEMENT_DAY_AVG_REQUESTED, inFn.getVolumeMovementDayAvg);
}

export function* watchResetGetWeeklyLow() {
  yield takeLatest(ActionTypes.WEEKLY_LOW_RESET, inFn.getWeeklyLow);
}
export function* watchGetWeeklyLow() {
  yield takeLatest(ActionTypes.WEEKLY_LOW_REQUESTED, inFn.getWeeklyLow);
}

export function* watchResetGetIndicesRRGData() {
  yield takeLatest(ActionTypes.RRG_DATA_RESET, inFn.getIndicesRRGData);
}
export function* watchGetIndicesRRGData() {
  yield takeLatest(ActionTypes.RRG_DATA_REQUESTED, inFn.getIndicesRRGData);
}
export function* watchResetGetOptionChainData() {
  yield takeLatest(ActionTypes.OPTION_CHAIN_DATA_RESET, inFn.getOptionsChainData);
}
export function* watchGetOptionChainData() {
  yield takeLatest(ActionTypes.OPTION_CHAIN_DATA_REQUESTED, inFn.getOptionsChainData);
}
export function* watchGetOptionsChainContractDateData() {
  yield takeLatest(ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_REQUESTED, inFn.getOptionsChainContractDateData);
}
export function* watchResetOptionsChainContractDateData() {
  yield takeLatest(ActionTypes.OPTION_CHAIN_CONTRACT_DATE_DATA_RESET, inFn.getOptionsChainContractDateData);
}
export function* watchResetGetFuturesData() {
  yield takeLatest(ActionTypes.FUTURES_DATA_RESET, inFn.getFuturesData);
}
export function* watchResetGetFuturesExpiryData() {
  yield takeLatest(ActionTypes.FUTURES_EXPIRY_DATA_RESET, inFn.getFuturesExpiryData);
}

export function* watchGetFuturesData() {
  yield takeLatest(ActionTypes.FUTURES_DATA_REQUESTED, inFn.getFuturesData);
}
export function* watchGetFuturesExpiryData() {
  yield takeLatest(ActionTypes.FUTURES_EXPIRY_DATA_REQUESTED, inFn.getFuturesExpiryData);
}


export function* watchGetEquityRecommendationsData() {
  yield takeLatest(ActionTypes.EQUITY_RECOMMENDATIONS_DATA_REQUESTED, inFn.getEquityRecommendationsData);
}

export function* watchGetEquityRecommendationsForReviewData() {
  yield takeLatest(ActionTypes.EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_REQUESTED, inFn.getEquityRecommendationsForReviewData);
}
export function* watchApproveRecommendation() {
  yield takeLatest(ActionTypes.EQUITY_RECOMMENDATIONS_APPROVE_REQUESTED, inFn.approveRecommendation);
}
export function* watchRejectRecommendation() {
  yield takeLatest(ActionTypes.EQUITY_RECOMMENDATIONS_REJECT_REQUESTED, inFn.rejectRecommendation);
}
export function* watchUserPorfolioAddUpdate() {
  yield takeLatest(ActionTypes.USER_PORTFOLIO_ADD_UPDATE_REQUESTED, inFn.updatePortfolio);
}
export function* watchUserPorfolioDelete() {
  yield takeLatest(ActionTypes.USER_PORTFOLIO_DELETE_REQUESTED, inFn.deletePortfolio);
}
export function* watchSendMessage() {
  yield takeLatest(ActionTypes.MESSAGE_SEND_REQUESTED, inFn.sendMessage);
}
export function* watchIndexEquityDerivativesWeightageData() {
  yield takeLatest(ActionTypes.INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_REQUESTED, inFn.getIndexEquityDerivativesWeightageData);
}
export function* watchGetMarketSummaryData() {
  yield takeLatest(ActionTypes.MARKET_SUMMARY_DATA_REQUESTED, inFn.getMarketSummaryData);
}


export function* watchEquityDerivativesWeightageData() {
  yield takeLatest(ActionTypes.DERIVATIVE_WEIGHTAGE_DATA_REQUESTED, inFn.getEquityDerivativeWeightage);
}
export function* watchEquityDerivativesWeightageHistoryData() {
  yield takeLatest(ActionTypes.DERIVATIVE_WEIGHTAGE_HISTORY_DATA_REQUESTED, inFn.getEquityDerivativeWeightageHistory);
}
export function* watchGETBulkBlockDeal() {
  yield takeLatest(ActionTypes.BULK_BLOCK_DEAL_DATA_REQUESTED, inFn.getBulkBlockDeal);
}

export function* watchGetEquityEvents() {
  yield takeLatest(ActionTypes.EQUITY_EVENTS_DATA_REQUESTED, inFn.getEquityEvents);
}
export function* watchDeleteWatchlist() {
  yield takeLatest(ActionTypes.USER_WATCHLIST_DELETE_REQUESTED, inFn.deleteWatchlist);
}
export function* watchUpdateWatchlist() {
  yield takeLatest(ActionTypes.USER_WATCHLIST_UPDATE_SYMBOLS_REQUESTED, inFn.updateWatchlist);
}
export function* watchDeleteSymbolsFromWatchlist() {
  yield takeLatest(ActionTypes.USER_WATCHLIST_DELETE_SYMBOLS_REQUESTED, inFn.deleteSymbolsFromWatchlist);
}
export function* watchAddWatchlist() {
  yield takeLatest(ActionTypes.USER_WATCHLIST_ADD_REQUESTED, inFn.addWatchlist);
}
export function* watchGetEquitiesByRegion() {
  yield takeLatest(ActionTypes.EQUITIES_BY_REGION_DATA_REQUESTED, inFn.getEquitiesByRegion);
}
export function* watchGetEvents() {
  yield takeLatest(ActionTypes.EVENTS_DATA_REQUESTED, inFn.getEvents);
}
export function* watchGetNewsFeeds() {
  yield takeLatest(ActionTypes.NEWSFEEDS_DATA_REQUESTED, inFn.getNewsFeed);
}
export function* watchGetPortfolioSwingPosition() {
  yield takeLatest(ActionTypes.PROTFOLIO_SWING_POSITION_DATA_REQUESTED, inFn.getPortfolioSwingPosition);
}
export function* watchGetNifty50SwingPosition() {
  yield takeLatest(ActionTypes.NIFTY50_SWING_POSITION_DATA_REQUESTED, inFn.getNifty50SwingPosition);
}
