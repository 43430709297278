export const RECOMMENDATION_LIMIT = 6
export const SUPER_ADMIN_USERS = {
  'symstock00@gmail.com': 1,
  'marketuv9@gmail.com': 1,
  'marketuva1@gmail.com': 1
};

export const VERTICAL_AD_SLOT_ID = "4721323572"
export const SQUARE_AD_SLOT_ID = "1050150806"
export const HORIZONTAL_AD_SLOT_ID = "2319000447"

