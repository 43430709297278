/**Auth Endpoints */
export const GOOGLE_AUTHBACK = '/api/auth/google';
export const FACEBOOK_AUTHBACK = '/api/auth/facebook';

/**User Endpoints */
export const USER_PROFILE_ENDPOINT = '/api/users/me';
export const USER_WATCHLIST_ENDPOINT = '/watchlists';
export const USER_WATCHLIST_ADD_ENDPOINT = '/watchlists';
export const USER_WATCHLIST_DELETE_BY_NAME_ENDPOINT = '/watchlists/deleteByName/{watchlistname}';
export const USER_WATCHLIST_ADD_SYMBOLS_ENDPOINT = '/watchlists/{watchlistid}/symbols';
export const USER_WATCHLIST_DELETE_SYMBOLS_ENDPOINT = '/watchlists/{watchlistid}/symbols';
export const USER_PORTFOLIO_ENDPOINT = '/portfolios';
export const USER_ADD_UPDATE_PORTFOLIO_ENDPOINT = '/portfolios';
export const USER_DELETE_PORTFOLIO_ENDPOINT = '/portfolios/deletebyname/{portfolioName}';

/**Equity Endpoints */
export const EQUITY_SEARCH_RESULTS_ENDPOINT = '/equities/all_equities_labels';
export const EQUITY_DATA_ENDPOINT = '/equities/{symbol}';
export const EQUITY_PRICES_ENDPOINT = '/equities/prices{symbol}';
export const MULTIPLE_EQUITY_PRICES_ENDPOINT = '/equities/prices';
export const EQUITY_TTPE_ENDPOINT = '/equities/{symbol}/tt';
export const EQUITY_WEEKLYSR_ENDPOINT = '/equities/{symbol}/weekly_sr';
export const EQUITY_REFRESH_FUNDAMENTAL_ENDPOINT = '/equities/{symbol}/refresh_fundamentals';
export const EQUITY_INTERVAL_HISTORY_DATA_ENDPOINT = '/equities/{symbol}/{interval}';
export const EQUITY_DELIVERY_DATA_ENDPOINT = '/equities/{symbol}/delivery/{interval}';
export const EQUITY_POSITION_BUILDUP_DATA_ENDPOINT = '/equities/{symbol}/postionBuildup';

/**Generic Endpoints */
export const VOLUME_MOVEMENT_ENDPOINT = '/volume-movement';
export const VOLUME_MOVEMENT_SYMBOL_ENDPOINT = '/volume-movement/{symbol}';
export const VOLUME_MOVEMENT_DAY_AVG_ENDPOINT = '/volume-movement-day-avg';
export const VOLUME_MOVEMENT_DAY_AVG_SYMBOL_ENDPOINT = '/volume-movement-day-avg/{symbol}';
export const WEEKLY_LOW_ENDPOINT = '/equities/weekly-low';

/*Indices Endpoints */
export const SECTOR_INDICES_RRG_DATA_ENDPOINT = '/equities/rrg';


/*Option Chain Endpoints */
export const OPTION_CHAIN_DATA_ENDPOINT = '/equities/option-chain';
export const OPTION_CHAIN_CONTRACT_DATE_DATA_ENDPOINT = '/equities/{symbol}/option-chain/{expiry}/{contract_date}'
/*Futures Endpoints */
export const FUTURES_DATA_ENDPOINT = '/equities/futures';
export const FUTURES_EXPIRY_DATA_ENDPOINT = '/equities/{symbol}/futures/expiry/{expiry}';
/*Equity Recommendations Endpoints */
export const EQUITY_RECOMMENDATIONS = '/equities/recommendations{count}';
export const EQUITY_RECOMMENDATIONS_FOR_REVIEW = '/equities/recommendations/review';
export const EQUITY_RECOMMENDATIONS_REVIEW_APPROVE = '/equities/recommendations/review/{recommendationId}/approve';
export const EQUITY_RECOMMENDATIONS_REVIEW_REJECT = '/equities/recommendations/review/{recommendationId}/reject';

/*Equity Recommendations Endpoints */
export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE = '/equities/derivatives/{symbol}/weightage';

/*NIFTY SUMMARY */
export const MARKET_SUMMARY = '/market/nifty/summary';


/*Send Message From Homepage */
export const SEND_MESSAGE = '/messages';

export const DERIVATIVE_WEIGHTAGE = '/equities/derivatives/weightage/{symbol}';
export const DERIVATIVE_WEIGHTAGE_HISTORY = '/equities/{symbol}/derivativeWeightageHistory'


export const BULK_BLOCK_DEAL = '/equities{symbol}/bulkBlockDeal'

export const EQUITY_EVENTS = '/equities/{symbol}/events'
export const EVENTS = '/events'

export const EQUITIES_BY_REGION = '/equities/byregion'
export const NEWSFEEDS = '/newsfeeds'
export const PROTFOLIO_SWING_POSITION = '/equities/portfolio/swingPosition'
export const NIFTY50_SWING_POSITION = '/equities/getNiftySwingPosition'