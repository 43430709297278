// export const API_ADDRESS = '//localhost:3000';
// export const API_ADDRESS_OAUTH = '//localhost:3002';

export const KITE_API_ADDRESS = 'https://kite.zerodha.com/connect/login?v=3&api_key=2rm81iwkgu4t7pih';

export const API_ADDRESS = '//www.marketuv.com';
export const API_ADDRESS_OAUTH = '//www.marketuv.com';


// export const API_ADDRESS = '//8fb3-2405-201-c03c-c-b456-b448-6fe5-8834.ngrok.io';
// export const API_ADDRESS_OAUTH = '//8fb3-2405-201-c03c-c-b456-b448-6fe5-8834.ngrok.io';
