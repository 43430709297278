import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { logger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import SuspenseView from '../src/components/suspense/suspense'
import './index.css';
import rootReducer from './reducers';
import rootSaga from './sagas';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import('./App'));


const persistConfig = {
  key: 'root',
  storage,
}
let persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, logger)
);
sagaMiddleware.run(rootSaga);
const persistor=persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<SuspenseView />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

function sendToAnalytics({ id, name, value }) {
  window.dataLayer.push({
    event: name,
    eventCategory: 'Web Vitals',
    // eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
    user_id: localStorage.getItem('user-id') || 'na',
  });
  // gtag('event', name, {
  //   eventCategory: 'Web Vitals',
  //   // eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  //   user_id: localStorage.getItem('user-id') || 'na',
    
  //   // page_title: '<Page Title>',
  //   // page_location: '<Page Location>',
  //   // page_path: '<Page Path>',
  //   // send_to: '<GA_MEASUREMENT_ID>'
  // })

  // ga('send', 'event', {
  //   eventCategory: 'Web Vitals',
  //   eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  // });
}
reportWebVitals(sendToAnalytics);