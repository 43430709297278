/**Generic Actions*/
/**User Actions*/
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const UPDATE_COOKIE = "UPDATE_COOKIE";

export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_SUCCEEDED = 'PROFILE_SUCCEEDED';
export const PROFILE_FAILED = 'PROFILE_FAILED';

export const TOGGLE_MODAL_REQUESTED = 'TOGGLE_MODAL_REQUESTED';
export const TOGGLE_MODAL_SUCCEEDED = 'TOGGLE_MODAL_SUCCEEDED';

export const USER_PORTFOLIO_REQUESTED = 'USER_PORTFOLIO_REQUESTED';
export const USER_PORTFOLIO_SUCCEEDED = 'USER_PORTFOLIO_SUCCEEDED';
export const USER_PORTFOLIO_FAILED = 'USER_PORTFOLIO_FAILED';

export const USER_PORTFOLIO_ADD_UPDATE_REQUESTED = 'USER_PORTFOLIO_ADD_UPDATE_REQUESTED';
export const USER_PORTFOLIO_ADD_UPDATE_SUCCEEDED = 'USER_PORTFOLIO_ADD_UPDATE_SUCCEEDED';
export const USER_PORTFOLIO_ADD_UPDATE_FAILED = 'USER_PORTFOLIO_ADD_UPDATE_FAILED';
export const USER_PORTFOLIO_ADD_UPDATE_DATA_RESET = 'USER_PORTFOLIO_ADD_UPDATE_DATA_RESET';

export const USER_PORTFOLIO_DELETE_REQUESTED = 'USER_PORTFOLIO_DELETE_REQUESTED';
export const USER_PORTFOLIO_DELETE_SUCCEEDED = 'USER_PORTFOLIO_DELETE_SUCCEEDED';
export const USER_PORTFOLIO_DELETE_FAILED = 'USER_PORTFOLIO_DELETE_FAILED';
export const USER_PORTFOLIO_DELETE_DATA_RESET = 'USER_PORTFOLIO_DELETE_DATA_RESET';


export const USER_WATCHLIST_REQUESTED = 'USER_WATCHLIST_REQUESTED';
export const USER_WATCHLIST_SUCCEEDED = 'USER_WATCHLIST_SUCCEEDED';
export const USER_WATCHLIST_FAILED = 'USER_WATCHLIST_FAILED';
export const USER_WATCHLIST_ADD_UPDATE_DATA_RESET = 'USER_WATCHLIST_ADD_UPDATE_DATA_RESET';


export const USER_WATCHLIST_ADD_REQUESTED = 'USER_WATCHLIST_ADD_REQUESTED';
export const USER_WATCHLIST_ADD_SUCCEEDED = 'USER_WATCHLIST_ADD_SUCCEEDED';
export const USER_WATCHLIST_ADD_FAILED = 'USER_WATCHLIST_ADD_FAILED';
export const USER_WATCHLIST_ADD_DATA_RESET = 'USER_WATCHLIST_ADD_DATA_RESET';

export const USER_WATCHLIST_UPDATE_SYMBOLS_REQUESTED = 'USER_WATCHLIST_UPDATE_SYMBOLS_REQUESTED';
export const USER_WATCHLIST_UPDATE_SYMBOLS_SUCCEEDED = 'USER_WATCHLIST_UPDATE_SYMBOLS_SUCCEEDED';
export const USER_WATCHLIST_UPDATE_SYMBOLS_FAILED = 'USER_WATCHLIST_UPDATE_SYMBOLS_FAILED';
export const USER_WATCHLIST_UPDATE_SYMBOLS_DATA_RESET = 'USER_WATCHLIST_UPDATE_SYMBOLS_DATA_RESET';

export const USER_WATCHLIST_DELETE_SYMBOLS_REQUESTED = 'USER_WATCHLIST_DELETE_SYMBOLS_REQUESTED';
export const USER_WATCHLIST_DELETE_SYMBOLS_SUCCEEDED = 'USER_WATCHLIST_DELETE_SYMBOLS_SUCCEEDED';
export const USER_WATCHLIST_DELETE_SYMBOLS_FAILED = 'USER_WATCHLIST_DELETE_SYMBOLS_FAILED';
export const USER_WATCHLIST_DELETE_SYMBOLS_DATA_RESET = 'USER_WATCHLIST_DELETE_SYMBOLS_DATA_RESET';


export const USER_WATCHLIST_DELETE_REQUESTED = 'USER_WATCHLIST_DELETE_REQUESTED';
export const USER_WATCHLIST_DELETE_SUCCEEDED = 'USER_WATCHLIST_DELETE_SUCCEEDED';
export const USER_WATCHLIST_DELETE_FAILED = 'USER_WATCHLIST_DELETE_FAILED';
export const USER_WATCHLIST_DELETE_DATA_RESET = 'USER_WATCHLIST_DELETE_DATA_RESET';



/**Equity Actions*/
export const EQUITY_SEARCH_RESULTS_REQUESTED = 'EQUITY_SEARCH_RESULTS_REQUESTED';
export const EQUITY_SEARCH_RESULTS_SUCCEEDED = 'EQUITY_SEARCH_RESULTS_SUCCEEDED';
export const EQUITY_SEARCH_RESULTS_FAILED = 'EQUITY_SEARCH_RESULTS_FAILED';

export const EQUITY_PRICE_REQUESTED = 'EQUITY_PRICE_REQUESTED';
export const EQUITY_PRICE_RESULTS_SUCCEEDED = 'EQUITY_PRICE_RESULTS_SUCCEEDED';
export const EQUITY_PRICE_RESULTS_FAILED = 'EQUITY_PRICE_RESULTS_FAILED';

export const MULTIPLE_EQUITY_PRICE_REQUESTED = 'MULTIPLE_EQUITY_PRICE_REQUESTED';
export const MULTIPLE_EQUITY_PRICE_RESULTS_SUCCEEDED = 'MULTIPLE_EQUITY_PRICE_RESULTS_SUCCEEDED';
export const MULTIPLE_EQUITY_PRICE_RESULTS_FAILED = 'MULTIPLE_EQUITY_PRICE_RESULTS_FAILED';
export const MULTIPLE_EQUITY_PRICE_RESET_SUCCEEDED = 'MULTIPLE_EQUITY_PRICE_RESULTS_SUCCEEDED';

export const EQUITY_PRICE_RESET = 'EQUITY_PRICE_RESET';
export const EQUITY_PRICE_RESET_SUCCEEDED = 'EQUITY_PRICE_RESET_SUCCEEDED';

export const EQUITY_DATA_RESET = 'EQUITY_DATA_RESET';
export const EQUITY_DATA_RESET_SUCCEEDED = 'EQUITY_DATA_RESET_SUCCEEDED';
export const EQUITY_DATA_REQUESTED = 'EQUITY_DATA_REQUESTED';
export const EQUITY_DATA_SUCCEEDED = 'EQUITY_DATA_SUCCEEDED';
export const EQUITY_DATA_FAILED = 'EQUITY_DATA_FAILED';

export const EQUITY_TTPE_RESET = 'EQUITY_TTPE_RESET';
export const EQUITY_TTPE_RESET_SUCCEEDED = 'EQUITY_TTPE_RESET_SUCCEEDED';
export const EQUITY_TTPE_REQUESTED = 'EQUITY_TTPE_REQUESTED';
export const EQUITY_TTPE_SUCCEEDED = 'EQUITY_TTPE_SUCCEEDED';
export const EQUITY_TTPE_FAILED = 'EQUITY_TTPE_FAILED';

export const EQUITY_SR_RESET = 'EQUITY_SR_RESET';
export const EQUITY_SR_RESET_SUCCEEDED = 'EQUITY_SR_RESET_SUCCEEDED';
export const EQUITY_SR_REQUESTED = 'EQUITY_SR_REQUESTED';
export const EQUITY_SR_SUCCEEDED = 'EQUITY_SR_SUCCEEDED';
export const EQUITY_SR_FAILED = 'EQUITY_SR_FAILED';

export const EQUITY_REFRESH_FUNDAMENTAL_RESET = 'EQUITY_REFRESH_FUNDAMENTAL_RESET';
export const EQUITY_REFRESH_FUNDAMENTAL_RESET_SUCCEEDED = 'EQUITY_REFRESH_FUNDAMENTAL_RESET_SUCCEEDED';
export const EQUITY_REFRESH_FUNDAMENTAL_REQUESTED = 'EQUITY_REFRESH_FUNDAMENTAL_REQUESTED';
export const EQUITY_REFRESH_FUNDAMENTAL_SUCCEEDED = 'EQUITY_REFRESH_FUNDAMENTAL_SUCCEEDED';
export const EQUITY_REFRESH_FUNDAMENTAL_FAILED = 'EQUITY_REFRESH_FUNDAMENTAL_FAILED';

export const EQUITY_INTERVAL_HISTORY_DATA_RESET = 'EQUITY_INTERVAL_HISTORY_DATA_RESET';
export const EQUITY_INTERVAL_HISTORY_DATA_RESET_SUCCEEDED = 'EQUITY_INTERVAL_HISTORY_DATA_RESET_SUCCEEDED';
export const EQUITY_INTERVAL_HISTORY_DATA_REQUESTED = 'EQUITY_INTERVAL_HISTORY_DATA_REQUESTED';
export const EQUITY_INTERVAL_HISTORY_DATA_SUCCEEDED = 'EQUITY_INTERVAL_HISTORY_DATA_SUCCEEDED';
export const EQUITY_INTERVAL_HISTORY_DATA_FAILED = 'EQUITY_INTERVAL_HISTORY_DATA_FAILED';

export const EQUITY_DELIVERY_DATA_RESET = 'EQUITY_DELIVERY_DATA_RESET';
export const EQUITY_DELIVERY_DATA_RESET_SUCCEEDED = 'EQUITY_DELIVERY_DATA_RESET_SUCCEEDED';
export const EQUITY_DELIVERY_DATA_REQUESTED = 'EQUITY_DELIVERY_DATA_REQUESTED';
export const EQUITY_DELIVERY_DATA_SUCCEEDED = 'EQUITY_DELIVERY_DATA_SUCCEEDED';
export const EQUITY_DELIVERY_DATA_FAILED = 'EQUITY_DELIVERY_DATA_FAILED';

export const EQUITY_POSITION_BUILDUP_DATA_RESET = 'EQUITY_POSITION_BUILDUP_DATA_RESET';
export const EQUITY_POSITION_BUILDUP_DATA_RESET_SUCCEEDED = 'EQUITY_POSITION_BUILDUP_DATA_RESET_SUCCEEDED';
export const EQUITY_POSITION_BUILDUP_DATA_REQUESTED = 'EQUITY_POSITION_BUILDUP_DATA_REQUESTED';
export const EQUITY_POSITION_BUILDUP_DATA_SUCCEEDED = 'EQUITY_POSITION_BUILDUP_DATA_SUCCEEDED';
export const EQUITY_POSITION_BUILDUP_DATA_FAILED = 'EQUITY_POSITION_BUILDUP_DATA_FAILED';

/**Generic Actions*/
export const VOLUME_MOVEMENT_RESET = 'VOLUME_MOVEMENT_RESET';
export const VOLUME_MOVEMENT_RESET_SUCCEEDED = 'VOLUME_MOVEMENT_RESET_SUCCEEDED';
export const VOLUME_MOVEMENT_REQUESTED = 'VOLUME_MOVEMENT_REQUESTED';
export const VOLUME_MOVEMENT_SUCCEEDED = 'VOLUME_MOVEMENT_SUCCEEDED';
export const VOLUME_MOVEMENT_FAILED = 'VOLUME_MOVEMENT_FAILED';

export const VOLUME_MOVEMENT_DAY_AVG_RESET = 'VOLUME_MOVEMENT_DAY_AVG_RESET';
export const VOLUME_MOVEMENT_DAY_AVG_RESET_SUCCEEDED = 'VOLUME_MOVEMENT_DAY_AVG_RESET_SUCCEEDED';
export const VOLUME_MOVEMENT_DAY_AVG_REQUESTED = 'VOLUME_MOVEMENT_DAY_AVG_REQUESTED';
export const VOLUME_MOVEMENT_DAY_AVG_SUCCEEDED = 'VOLUME_MOVEMENT_DAY_AVG_SUCCEEDED';
export const VOLUME_MOVEMENT_DAY_AVG_FAILED = 'VOLUME_MOVEMENT_DAY_AVG_FAILED';

export const WEEKLY_LOW_RESET = 'WEEKLY_LOW_RESET';
export const WEEKLY_LOW_RESET_SUCCEEDED = 'WEEKLY_LOW_RESET_SUCCEEDED';
export const WEEKLY_LOW_REQUESTED = 'WEEKLY_LOW_REQUESTED';
export const WEEKLY_LOW_SUCCEEDED = 'WEEKLY_LOW_SUCCEEDED';
export const WEEKLY_LOW_FAILED = 'WEEKLY_LOW_FAILED';


export const RRG_DATA_RESET = 'RRG_DATA_RESET';
export const RRG_DATA_RESET_SUCCEEDED = 'RRG_DATA_RESET_SUCCEEDED';
export const RRG_DATA_REQUESTED = 'RRG_DATA_REQUESTED';
export const RRG_DATA_SUCCEEDED = 'RRG_DATA_SUCCEEDED';
export const RRG_DATA_FAILED = 'RRG_DATA_FAILED';


export const OPTION_CHAIN_DATA_RESET = 'OPTION_CHAIN_DATA_RESET';
export const OPTION_CHAIN_DATA_RESET_SUCCEEDED = 'OPTION_CHAIN_DATA_RESET_SUCCEEDED';
export const OPTION_CHAIN_DATA_REQUESTED = 'OPTION_CHAIN_DATA_REQUESTED';
export const OPTION_CHAIN_DATA_SUCCEEDED = 'OPTION_CHAIN_DATA_SUCCEEDED';
export const OPTION_CHAIN_DATA_FAILED = 'OPTION_CHAIN_DATA_FAILED';

export const FUTURES_DATA_RESET = 'FUTURES_DATA_RESET';
export const FUTURES_DATA_RESET_SUCCEEDED = 'FUTURES_DATA_RESET_SUCCEEDED';
export const FUTURES_DATA_REQUESTED = 'FUTURES_DATA_REQUESTED';
export const FUTURES_DATA_SUCCEEDED = 'FUTURES_DATA_SUCCEEDED';
export const FUTURES_DATA_FAILED = 'FUTURES_DATA_FAILED';

export const FUTURES_EXPIRY_DATA_RESET = 'FUTURES_EXPIRY_DATA_RESET';
export const FUTURES_EXPIRY_DATA_RESET_SUCCEEDED = 'FUTURES_EXPIRY_DATA_RESET_SUCCEEDED';
export const FUTURES_EXPIRY_DATA_REQUESTED = 'FUTURES_EXPIRY_DATA_REQUESTED';
export const FUTURES_EXPIRY_DATA_SUCCEEDED = 'FUTURES_EXPIRY_DATA_SUCCEEDED';
export const FUTURES_EXPIRY_DATA_FAILED = 'FUTURES_EXPIRY_DATA_FAILED';
export const FUTURES_EXPIRY_DATA_UPDATE_FLAG = 'FUTURES_EXPIRY_DATA_UPDATE_FLAG';


export const EQUITY_RECOMMENDATIONS_DATA_RESET = 'EQUITY_RECOMMENDATIONS_DATA_RESET';
export const EQUITY_RECOMMENDATIONS_DATA_RESET_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_DATA_RESET_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_DATA_REQUESTED = 'EQUITY_RECOMMENDATIONS_DATA_REQUESTED';
export const EQUITY_RECOMMENDATIONS_DATA_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_DATA_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_DATA_FAILED = 'EQUITY_RECOMMENDATIONS_DATA_FAILED';

export const EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_RESET = 'EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_RESET';
export const EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_RESET_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_RESET_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_REQUESTED = 'EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_REQUESTED';
export const EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_FAILED = 'EQUITY_RECOMMENDATIONS_FOR_REVIEW_DATA_FAILED';

export const EQUITY_RECOMMENDATIONS_APPROVE_REQUESTED = 'EQUITY_RECOMMENDATIONS_APPROVE_REQUESTED';
export const EQUITY_RECOMMENDATIONS_APPROVE_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_APPROVE_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_APPROVE_FAILED = 'EQUITY_RECOMMENDATIONS_APPROVE_FAILED';

export const EQUITY_RECOMMENDATIONS_REJECT_REQUESTED = 'EQUITY_RECOMMENDATIONS_REJECT_REQUESTED';
export const EQUITY_RECOMMENDATIONS_REJECT_SUCCEEDED = 'EQUITY_RECOMMENDATIONS_REJECT_SUCCEEDED';
export const EQUITY_RECOMMENDATIONS_REJECT_FAILED = 'EQUITY_RECOMMENDATIONS_REJECT_FAILED';


export const OPTION_CHAIN_CONTRACT_DATE_DATA_RESET = 'OPTION_CHAIN_CONTRACT_DATE_DATA_RESET';
export const OPTION_CHAIN_CONTRACT_DATE_DATA_RESET_SUCCEEDED = 'OPTION_CHAIN_CONTRACT_DATE_DATA_RESET_SUCCEEDED';
export const OPTION_CHAIN_CONTRACT_DATE_DATA_REQUESTED = 'OPTION_CHAIN_CONTRACT_DATE_DATA_REQUESTED';
export const OPTION_CHAIN_CONTRACT_DATE_DATA_SUCCEEDED = 'OPTION_CHAIN_CONTRACT_DATE_DATA_SUCCEEDED';
export const OPTION_CHAIN_CONTRACT_DATE_DATA_FAILED = 'OPTION_CHAIN_CONTRACT_DATE_DATA_FAILED';


export const MESSAGE_SEND_REQUESTED = 'MESSAGE_SEND_REQUESTED';
export const MESSAGE_SEND_SUCCEEDED = 'MESSAGE_SEND_SUCCEEDED';
export const MESSAGE_SEND_FAILED = 'MESSAGE_SEND_FAILED';


export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_RESET = 'EQUITY_DERIVATIVES_WEIGHTAGE_DATA_RESET';
export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_RESET_SUCCEEDED = 'EQUITY_DERIVATIVES_WEIGHTAGE_DATA_RESET_SUCCEEDED';
export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_REQUESTED = 'EQUITY_DERIVATIVES_WEIGHTAGE_DATA_REQUESTED';
export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_SUCCEEDED = 'EQUITY_DERIVATIVES_WEIGHTAGE_DATA_SUCCEEDED';
export const INDEX_EQUITY_DERIVATIVES_WEIGHTAGE_DATA_FAILED = 'EQUITY_DERIVATIVES_WEIGHTAGE_DATA_FAILED';

export const MARKET_SUMMARY_DATA_RESET = 'MARKET_SUMMARY_DATA_RESET';
export const MARKET_SUMMARY_DATA_RESET_SUCCEEDED = 'MARKET_SUMMARY_DATA_RESET_SUCCEEDED';
export const MARKET_SUMMARY_DATA_REQUESTED = 'MARKET_SUMMARY_DATA_REQUESTED';
export const MARKET_SUMMARY_DATA_SUCCEEDED = 'MARKET_SUMMARY_DATA_SUCCEEDED';
export const MARKET_SUMMARY_DATA_FAILED = 'MARKET_SUMMARY_DATA_FAILED';

export const DERIVATIVE_WEIGHTAGE_DATA_RESET = 'DERIVATIVE_WEIGHTAGE_DATA_RESET';
export const DERIVATIVE_WEIGHTAGE_DATA_RESET_SUCCEEDED = 'DERIVATIVE_WEIGHTAGE_DATA_RESET_SUCCEEDED';
export const DERIVATIVE_WEIGHTAGE_DATA_REQUESTED = 'DERIVATIVE_WEIGHTAGE_DATA_REQUESTED';
export const DERIVATIVE_WEIGHTAGE_DATA_SUCCEEDED = 'DERIVATIVE_WEIGHTAGE_DATA_SUCCEEDED';
export const DERIVATIVE_WEIGHTAGE_DATA_FAILED = 'DERIVATIVE_WEIGHTAGE_DATA_FAILED';

export const DERIVATIVE_WEIGHTAGE_HISTORY_DATA_RESET = 'DERIVATIVE_WEIGHTAGE_HISTORY_DATA_RESET';
export const DERIVATIVE_WEIGHTAGE_HISTORY_DATA_RESET_SUCCEEDED = 'DERIVATIVE_WEIGHTAGE_HISTORY_DATA_RESET_SUCCEEDED';
export const DERIVATIVE_WEIGHTAGE_HISTORY_DATA_REQUESTED = 'DERIVATIVE_WEIGHTAGE_HISTORY_DATA_REQUESTED';
export const DERIVATIVE_WEIGHTAGE_HISTORY_DATA_SUCCEEDED = 'DERIVATIVE_WEIGHTAGE_HISTORY_DATA_SUCCEEDED';
export const DERIVATIVE_WEIGHTAGE_HISTORY_DATA_FAILED = 'DERIVATIVE_WEIGHTAGE_HISTORY_DATA_FAILED';

export const BULK_BLOCK_DEAL_DATA_RESET = 'BULK_BLOCK_DEAL_DATA_RESET';
export const BULK_BLOCK_DEAL_DATA_RESET_SUCCEEDED = 'BULK_BLOCK_DEAL_DATA_RESET_SUCCEEDED';
export const BULK_BLOCK_DEAL_DATA_REQUESTED = 'BULK_BLOCK_DEAL_DATA_REQUESTED';
export const BULK_BLOCK_DEAL_DATA_SUCCEEDED = 'BULK_BLOCK_DEAL_DATA_SUCCEEDED';
export const BULK_BLOCK_DEAL_DATA_FAILED = 'BULK_BLOCK_DEAL_DATA_FAILED';


export const EQUITY_EVENTS_DATA_RESET = 'EQUITY_EVENTS_DATA_RESET';
export const EQUITY_EVENTS_DATA_RESET_SUCCEEDED = 'EQUITY_EVENTS_DATA_RESET_SUCCEEDED';
export const EQUITY_EVENTS_DATA_REQUESTED = 'EQUITY_EVENTS_DATA_REQUESTED';
export const EQUITY_EVENTS_DATA_SUCCEEDED = 'EQUITY_EVENTS_DATA_SUCCEEDED';
export const EQUITY_EVENTS_DATA_FAILED = 'EQUITY_EVENTS_DATA_FAILED';

export const EVENTS_DATA_RESET = 'EVENTS_DATA_RESET';
export const EVENTS_DATA_RESET_SUCCEEDED = 'EVENTS_DATA_RESET_SUCCEEDED';
export const EVENTS_DATA_REQUESTED = 'EVENTS_DATA_REQUESTED';
export const EVENTS_DATA_SUCCEEDED = 'EVENTS_DATA_SUCCEEDED';
export const EVENTS_DATA_FAILED = 'EVENTS_DATA_FAILED';


export const EQUITIES_BY_REGION_DATA_RESET = 'EQUITIES_BY_REGION_DATA_RESET';
export const EQUITIES_BY_REGION_DATA_RESET_SUCCEEDED = 'EQUITIES_BY_REGION_DATA_RESET_SUCCEEDED';
export const EQUITIES_BY_REGION_DATA_REQUESTED = 'EQUITIES_BY_REGION_DATA_REQUESTED';
export const EQUITIES_BY_REGION_DATA_SUCCEEDED = 'EQUITIES_BY_REGION_DATA_SUCCEEDED';
export const EQUITIES_BY_REGION_DATA_FAILED = 'EQUITIES_BY_REGION_DATA_FAILED';

export const NEWSFEEDS_DATA_RESET = 'NEWSFEEDS_DATA_RESET';
export const NEWSFEEDS_DATA_RESET_SUCCEEDED = 'NEWSFEEDS_DATA_RESET_SUCCEEDED';
export const NEWSFEEDS_DATA_REQUESTED = 'NEWSFEEDS_DATA_REQUESTED';
export const NEWSFEEDS_DATA_SUCCEEDED = 'NEWSFEEDS_DATA_SUCCEEDED';
export const NEWSFEEDS_DATA_FAILED = 'NEWSFEEDS_DATA_FAILED';

export const PROTFOLIO_SWING_POSITION_DATA_RESET = 'PROTFOLIO_SWING_POSITION_DATA_RESET';
export const PROTFOLIO_SWING_POSITION_DATA_RESET_SUCCEEDED = 'PROTFOLIO_SWING_POSITION_DATA_RESET_SUCCEEDED';
export const PROTFOLIO_SWING_POSITION_DATA_REQUESTED = 'PROTFOLIO_SWING_POSITION_DATA_REQUESTED';
export const PROTFOLIO_SWING_POSITION_DATA_SUCCEEDED = 'PROTFOLIO_SWING_POSITION_DATA_SUCCEEDED';
export const PROTFOLIO_SWING_POSITION_DATA_FAILED = 'PROTFOLIO_SWING_POSITION_DATA_FAILED';

export const NIFTY50_SWING_POSITION_DATA_RESET = 'NIFTY50_SWING_POSITION_DATA_RESET';
export const NIFTY50_SWING_POSITION_DATA_RESET_SUCCEEDED = 'NIFTY50_SWING_POSITION_DATA_RESET_SUCCEEDED';
export const NIFTY50_SWING_POSITION_DATA_REQUESTED = 'NIFTY50_SWING_POSITION_DATA_REQUESTED';
export const NIFTY50_SWING_POSITION_DATA_SUCCEEDED = 'NIFTY50_SWING_POSITION_DATA_SUCCEEDED';
export const NIFTY50_SWING_POSITION_DATA_FAILED = 'NIFTY50_SWING_POSITION_DATA_FAILED';