
//import { tsvParse, csvParse } from  "d3-dsv";
import { csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";
import _ from "lodash";


// function parseData(parseDate, parseDate2) {
// 	return function(d) {
// 		let d1 = {}
// 		d1.date = d.Date;
// 		d1.open = +parseFloat(d.Open);
// 		d1.high = +parseFloat(d.High);
// 		d1.low = +parseFloat(d.Low);
// 		d1.close = +parseFloat(d.Close);
// 		d1.volume = +parseFloat(d.Volume);
// 		d1.mom = +parseFloat(d.MOM);
// 		d1.rsi = +parseFloat(d.RSI);
		

// 		return d1;
// 	};
// }

function parseData(data, columns) {
		let d1 = {}
		_.each(data, (value, idx) => {
			d1[columns[idx].toLowerCase()] = value;
		})
		d1.date = parseDate(d1.date)
		return d1;
}

export const parseDate = timeParse("%Y-%m-%d %H:%M:%S");
export const parseDate2 = timeParse("%Y-%m-%dT%H:%M:%S.000Z");

export function getData(data, columns) {
	let finalData = [], parsedData;
	_.each(data, (row) => {
		parsedData = parseData(row, columns)
		finalData.push(parsedData);
	})
	return finalData;
	// return csvParse(data, parseData(parseDate, parseDate2))
}

